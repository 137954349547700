<template>
  <div>
    <setup-table
      :title="`Location`"
      :items="items"
      :headers="headers"
      :loading="loading"
      :clearItem="!showForm || !editedItem.id"
      @createItem="createItem()"
      @editItem="editItem"
      @deleteItems="deleteItems"
      showSearch
      pagination
    >
      <template #itemForm v-if="showForm">
        <location-form
          ref="locationForm"
          :value="editedItem"
          :valueRoles="valueRoles"
          :vehicleLocations="vehicleLocations"
          :zoneItems="zoneItems"
          :users="users"
          @submit="saveItem"
          @closeForm="closeForm"
        ></location-form>
      </template>

      <template #[`item.synced`]="{ item }">
        <v-icon small v-if="item.synced">mdi-sync</v-icon>
      </template>
      <template #[`item.name`]="{ item }">
        <p class="mb-0">{{ `${item.name} (${item.abbr})` }}</p>
      </template>
      <template #[`item.type`]="{ item }">
        <v-icon small v-if="item.schoolId">mdi-school</v-icon>
        <v-icon small v-if="item.depotId">mdi-bus</v-icon>
      </template>
    </setup-table>

    <location-manager ref="slaManager" @refresh="fetchItems"></location-manager>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_LOCATIONS, SAVE_LOCATION, DELETE_LOCATIONS, GET_ZONES } from '@/store/modules/Location/actions';
import { GET_USERS, ASSIGN_ROLES, ASSIGN_LOCATION_ROLES, GET_ROLE_ASSIGNMENTS } from '@/store/modules/User/actions';
import { GET_STOPS } from '@/store/modules/Stop/actions';
import SetupTable from './SetupTable.vue';
import LocationForm from './LocationForm.vue';
import LocationManager from './LocationManager.vue';

export default {
  name: 'LocationSetupTable',
  inject: ['eventHub'],
  components: { SetupTable, LocationForm, LocationManager },
  data() {
    return {
      showForm: false,
      items: [],
      vehicleLocations: [],
      editedItem: {},
      valueRoles: {
        siteAdmins: [],
        siteAdminsReadOnly: [],
        siteAdminsReportsOnly: [],
        paysInvoices: [],
        vehicleOwner: [],
        specialNeedsVehicleOwner: [],
        nurses: [],
        nutritionists: [],
      },
      loading: true,
      roles: [],
    };
  },
  computed: {
    ...mapGetters('app', ['isRoutingClient']),
    ...mapGetters('location', ['locations', 'zoneItems']),
    ...mapGetters('user', ['users']),
    headers() {
      const h = [
        {
          text: 'Code',
          value: 'code',
          sortable: true,
        },
        { text: 'Name', value: 'name' },
        { text: 'Zone', value: 'zone' },
        { text: '', value: 'type' },
      ];
      if (this.isRoutingClient) h.unshift({ text: '', value: 'synced' });
      return h;
    },
  },
  mounted() {
    this.eventHub.$on('manageLocationSLARequested', () => this.manageLocationSLA());
    this.fetchItems();
  },
  beforeDestroy() {
    this.eventHub.$off('manageLocationSLARequested');
  },
  methods: {
    ...mapActions('location', [GET_LOCATIONS, SAVE_LOCATION, DELETE_LOCATIONS, GET_ZONES]),
    ...mapActions('user', [GET_USERS, ASSIGN_ROLES, ASSIGN_LOCATION_ROLES, GET_ROLE_ASSIGNMENTS]),
    ...mapActions('stop', [GET_STOPS]),
    async fetchItems() {
      await this.getLocations({ forTable: true });
      this.items = this.locations;
      this.vehicleLocations = this.items.filter((e) => e.depotId);
      await this.getZones();
      await this.getUsers();
      await this.getStops();
      this.roles = await this.getRoleAssignments();
      this.loading = false;
    },
    createItem() {
      this.editedItem = { id: 0, name: '', vehicleOrder: [] };
      this.showForm = true;
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.valueRoles = {
        siteAdmins: this.roles.filter((e) => e.roleId == 10 && e.locationIds.includes(item.id)),
        siteAdminsReadOnly: this.roles.filter((e) => e.roleId == 11 && e.locationIds.includes(item.id)),
        siteAdminsReportsOnly: this.roles.filter((e) => e.roleId == 12 && e.locationIds.includes(item.id)),
        paysInvoices: this.roles.filter((e) => e.roleId == 13 && e.locationIds.includes(item.id)),
        vehicleOwner: this.roles.filter((e) => e.roleId == 14 && e.locationIds.includes(item.id)),
        specialNeedsVehicleOwner: this.roles.filter((e) => e.roleId == 15 && e.locationIds.includes(item.id)),
        nurses: this.roles.filter((e) => e.roleId == 16 && e.locationIds.includes(item.id)),
        nutritionists: this.roles.filter((e) => e.roleId == 17 && e.locationIds.includes(item.id)),
      };
      this.showForm = true;
    },
    async saveItem(item) {
      if (!item.address) this.$myalert.error('Address is required');
      else
        try {
          if (item.stop) item.stop.name = item.name;

          const r = await this.saveLocation(item);
          const r2 = await this.assignLocationRoles(item.roles);

          if (r && r.id && r2 && r2.done) {
            this.$myalert.success('Location saved');
            await this.fetchItems();
            this.editedItem = { ...item, id: r.id };
          }
        } catch (error) {
          this.$myalert.error(error.message);
        }
    },
    async deleteItems(items) {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          items.length > 1 ? 'these Locations' : 'this Location'
        }? This operation cannot be undone.`
      );
      if (yes) {
        try {
          const r = await this.deleteLocations(items.map((e) => e.id));
          if (r.done) {
            this.$myalert.success(`${items.length > 1 ? 'Locations' : 'Location'} deleted`);
            this.showForm = false;
            this.fetchItems();
          }
        } catch (error) {
          this.$myalert.error(error.message);
        }
      }
    },
    closeForm() {
      this.showForm = false;
    },
    manageLocationSLA() {
      this.$refs.slaManager.dialog = true;
    },
  },
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
