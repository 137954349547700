<template>
  <v-form :readonly="value.id > 0 && !canEditSetupTables">
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <v-btn @click="cancel(false)"> Cancel </v-btn>
          <v-btn v-if="canEditSetupTables" class="float-right" color="green" dark @click="onSubmit"> save </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-alert v-if="value.synced" outlined type="info" text>
        This Location is synced with a School and/or Depot Stop in EZRouting. Some fields will not be able to be
        modified here.
      </v-alert>

      <v-alert v-else-if="!value.id && isRoutingClient" outlined type="info" text>
        This Location will only be avilable in EZActivityTrips. If you would like this School or Depot Stop to be
        available in EZRouting, please add them there first and they will be synced with EZActivityTrips.
      </v-alert>

      <v-expansion-panels accordion mandatory>
        <v-expansion-panel>
          <v-expansion-panel-header>General</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  :value="value.code"
                  v-model.trim="$v.form.code.$model"
                  label="Code"
                  required
                  :readonly="value.synced && !!value.isSchool"
                  :error-messages="handleErrors($v.form.code)"
                  @blur="$v.form.code.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :value="value.name"
                  v-model.trim="$v.form.name.$model"
                  label="Name"
                  required
                  :readonly="value.synced"
                  :error-messages="handleErrors($v.form.name)"
                  @blur="$v.form.name.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  :value="value.abbr"
                  v-model.trim="$v.form.abbr.$model"
                  label="Abbreviation"
                  required
                  :error-messages="handleErrors($v.form.abbr)"
                  @blur="$v.form.abbr.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2">
                <v-checkbox
                  :value="value.active"
                  :readonly="value.synced"
                  v-model="$v.form.active.$model"
                  label="Active"
                  required
                  @change="$v.form.active.$touch()"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="2">
                <v-checkbox
                  :value="value.isSchool"
                  v-model="$v.form.isSchool.$model"
                  :readonly="value.synced"
                  label="School"
                  required
                  @change="$v.form.isSchool.$touch()"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="3">
                <v-checkbox
                  :value="value.isVehicleLocation"
                  v-model="$v.form.isVehicleLocation.$model"
                  label="Vehicle Location"
                  :readonly="value.synced"
                  required
                  @change="$v.form.isVehicleLocation.$touch()"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="5">
                <v-checkbox
                  :value="value.inclCommonDest"
                  v-model="$v.form.inclCommonDest.$model"
                  label="Include in Common Destinations"
                  required
                  @change="$v.form.inclCommonDest.$touch()"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="3">
                <v-combobox
                  :value="value.zone"
                  v-model.trim="$v.form.zone.$model"
                  :items="zoneItems"
                  label="Zone"
                  @blur="$v.form.zone.$touch()"
                  @input.native="$v.form.zone.$model = $event.srcElement.value"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" class="mb-0 pb-0">
                <v-select
                  label="Vehicle Order of Assignment"
                  :value="value.vehicleOrder"
                  v-model="$v.form.vehicleOrder.$model"
                  :items="vehicleLocations"
                  item-text="name"
                  item-value="id"
                  outlined
                  class="mb-0"
                  multiple
                  v-on:input="limiter($event, 4, 'Vehicle Locations')"
                >
                  <template v-slot:selection="{ item, parent }">
                    <v-chip>
                      <span class="pr-2">
                        {{ item.name }}
                      </span>
                      <v-icon @click="parent.selectItem(item)"> $delete </v-icon>
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header :class="{ 'red--text': isAddressFormInvalid() }">Address</v-expansion-panel-header>

          <v-expansion-panel-content eager>
            <destination-autocomplete
              :loading="loadingLocations"
              :includeDestinations="false"
              @destinationChanged="handleNewLocation"
              @internalLoading="(val) => (loadingLocations = val)"
              :searchInput="autocompleteSearchText"
              placeholder="Begin typing to search for a location..."
              radio-label="Search new locations by:"
              hint="Use this field to prefill the form below, or complete the form manually"
              outlined
              persistent-hint
              v-model="id"
            >
              <template #item="{ on, attrs, item }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-icon color="orange" left>mdi-map-marker-plus</v-icon>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </template>
            </destination-autocomplete>

            <v-divider class="my-6"></v-divider>

            <v-row class="pt-2">
              <address-form
                :readonly="value.synced"
                :multiple="false"
                :showLatLong="true"
                ref="aform"
                required
                v-model="$v.form.address.$model"
              ></address-form>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Site Level Authority</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="12" class="mb-0 pb-0">
                <user-select
                  :value="locationRoles.siteAdmins"
                  v-model.trim="locationRoles.siteAdmins"
                  :users="users"
                  label="Site Admins"
                  saveEmail
                  :multiple="true"
                  @handleUserSelect="handleRoleAssignment($event, 'siteAdmins')"
                >
                </user-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" class="mb-0 pb-0">
                <user-select
                  :value="locationRoles.siteAdminsReadOnly"
                  v-model.trim="locationRoles.siteAdminsReadOnly"
                  :users="users"
                  label="Site Admins - Read Only"
                  saveEmail
                  :multiple="true"
                  @handleUserSelect="handleRoleAssignment($event, 'siteAdminsReadOnly')"
                >
                </user-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" class="mb-0 pb-0">
                <user-select
                  :value="locationRoles.siteAdminsReportsOnly"
                  v-model.trim="locationRoles.siteAdminsReportsOnly"
                  :users="users"
                  label="Site Admins - Reports Only"
                  saveEmail
                  :multiple="true"
                  @handleUserSelect="handleRoleAssignment($event, 'siteAdminsReportsOnly')"
                >
                </user-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" class="mb-0 pb-0">
                <user-select
                  :value="locationRoles.paysInvoices"
                  v-model.trim="locationRoles.paysInvoices"
                  :users="users"
                  label="Pays Invoices"
                  noChips
                  saveEmail
                  @handleUserSelect="handleRoleAssignment($event, 'paysInvoices')"
                >
                </user-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" class="mb-0 pb-0">
                <user-select
                  :value="locationRoles.vehicleOwner"
                  v-model.trim="locationRoles.vehicleOwner"
                  :users="users"
                  label="Schedules Vehicles (Owner)"
                  noChips
                  saveEmail
                  @handleUserSelect="handleRoleAssignment($event, 'vehicleOwner')"
                >
                </user-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" class="mb-0 pb-0">
                <user-select
                  :value="locationRoles.specialNeedsVehicleOwner"
                  v-model.trim="locationRoles.specialNeedsVehicleOwner"
                  :users="users"
                  label="Schedules Special Needs Vehicles"
                  noChips
                  saveEmail
                  @handleUserSelect="handleRoleAssignment($event, 'specialNeedsVehicleOwner')"
                >
                </user-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" class="mb-0 pb-0">
                <user-select
                  :value="locationRoles.nurses"
                  v-model.trim="locationRoles.nurses"
                  :users="users"
                  label="School Nurse"
                  saveEmail
                  noChips
                  @handleUserSelect="handleRoleAssignment($event, 'nurses')"
                >
                </user-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" class="mb-0 pb-0">
                <user-select
                  :value="locationRoles.nutritionists"
                  v-model.trim="locationRoles.nutritionists"
                  :users="users"
                  label="Child Nutrition Notification Lunch Trips"
                  saveEmail
                  noChips
                  @handleUserSelect="handleRoleAssignment($event, 'nutritionists')"
                >
                </user-select>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Site Level Approvers</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="approverHeaders"
              :items="approverItems"
              dense
              hide-default-footer
              class="elevation-1 fixed-table"
            >
              <template #[`item.level`]="{ item }">
                <strong class="mb-0">{{ item.level }}</strong>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';

import { handleErrors } from '@/util';

import AddressForm from '@/components/AddressForm.vue';
import AddressFormValidations from '@/models/AddressFormValidation.model';
import DestinationAutocomplete from '@/components/DestinationAutocomplete.vue';
import UserSelect from '@/components/UserSelect.vue';

const newLocation = {
  code: '',
  name: '',
  abbr: '',
  active: true,
  inclCommonDest: true,
  isSchool: true,
  isVehicleLocation: false,
  zone: '',
  address: {},
  siteAdmins: [],
  vehicleOrder: [],
};

const rolesByName = {
  siteAdmins: 10,
  siteAdminsReadOnly: 11,
  siteAdminsReportsOnly: 12,
  paysInvoices: 13,
  vehicleOwner: 14,
  specialNeedsVehicleOwner: 15,
  nurses: 16,
  nutritionists: 17,
};

export default {
  mixins: [validationMixin],
  components: { AddressForm, DestinationAutocomplete, UserSelect },
  props: {
    value: {
      type: Object,
      default: () => newLocation,
    },
    valueRoles: {
      type: Object,
      default: () => {},
    },
    vehicleLocations: {
      type: Array,
      default: () => [],
    },
    zoneItems: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    handleErrors,
    panel: [0],
    id: null,
    form: newLocation,
    loadingLocations: false,
    locationRoles: {
      siteAdmins: [],
      siteAdminsReadOnly: [],
      siteAdminsReportsOnly: [],
      paysInvoices: '',
      vehicleOwner: '',
      specialNeedsVehicleOwner: '',
      nurses: [],
      nutritionists: [],
    },
    search: null,
    saved: false,
    saveRoles: [],
  }),
  validations: {
    form: {
      abbr: { required, maxLength: maxLength(10) },
      active: {},
      address: AddressFormValidations,
      autocompleteSearchText: '',
      childNutrition: {},
      code: { required },
      inclCommonDest: {},
      isSchool: {},
      isVehicleLocation: {},
      name: { required },
      paysInvoices: {},
      schoolNurse: {},
      siteAdmins: {},
      vehicleOrder: {},
      vehicleOwner: {},
      vehicleOwnerSpecial: {},
      zone: {},
    },
  },
  computed: {
    ...mapGetters('user', ['me', 'usersById', 'canEditSetupTables']),
    ...mapGetters('approvalLevel', ['approvalLevels']),
    ...mapGetters('tripType', ['tripTypes']),
    ...mapGetters('app', ['isRoutingClient']),
    approverHeaders() {
      const width = `${(1 / (this.tripTypes.length + 1)) * 100}%`;
      return [
        {
          text: 'Level',
          value: 'level',
          sortable: false,
          divider: true,
          width,
        },
      ].concat(
        this.tripTypes.map((e) => ({
          text: e.name,
          value: `${e.id}-approver`,
          sortable: false,
          divider: true,
          width,
          align: 'center',
        }))
      );
    },
    approverItems() {
      const approvers = [];
      for (let level of this.approvalLevels) {
        const obj = { level: level.name };
        for (let tt of this.tripTypes) {
          let primary;
          const includeTT = level.tripTypes.includes(tt.id);
          if (includeTT) {
            if (level.criteria.map((e) => e.label).includes('Funding Source'))
              primary = { userDisplayName: 'Based on Funding' };
            else
              primary = level.approvers.find(
                (a) => a.isPrimary && a.tripTypeIds.includes(tt.id) && a.locationIds.includes(this.value.id)
              );
          }
          obj[`${tt.id}-approver`] = primary ? primary.userDisplayName || primary.userEmail : includeTT ? null : 'x';
        }
        approvers.push(obj);
      }
      return approvers;
    },
  },
  methods: {
    async cancel(fromSave) {
      let ok = true;
      const changesMade = this.compareValues();
      if (!fromSave && changesMade)
        ok = await this.$myconfirm('Are you sure you want to close this form? Your changes will not be saved.');
      if (ok) {
        this.form = newLocation;
        if (this.$refs.aform) this.$refs.aform.clear();
        this.$emit('closeForm');
      }
    },
    onSubmit() {
      const addressForm = this.$refs.aform?.getCurrentForm();

      let hasError = false;

      if (this.$v.form.$invalid) {
        this.$v.form.$touch();
        hasError = true;
      }

      if (!addressForm || addressForm?.$invalid) {
        addressForm?.$touch();
        hasError = true;
      }

      if (hasError) {
        this.$myalert.error('Please complete all required fields');
        return;
      }

      this.$emit('submit', {
        id: this.id,
        ...this.$v.form.$model,
        address: this.$v.form.address.$model,
        roles: this.saveRoles,
      });

      this.saved = true;
    },
    limiter(e, max, item) {
      if (e.length > max) {
        this.$myalert.error(`You may only select up to ${max} ${item}${max > 1 ? 's' : ''}`);
        e.pop();
      }
    },
    handleRoleAssignment(users, details) {
      const currentIndex = this.saveRoles.findIndex((e) => e.roleId == rolesByName[details]);
      if (currentIndex >= 0) this.saveRoles.splice(currentIndex, 1);
      this.saveRoles.push({
        roleId: rolesByName[details],
        userEmails: Array.isArray(users) ? users : [users],
        locationIds: [this.value.id],
      });
    },
    addVehicleLocation() {
      this.value.vehicleOrder.push(null);
    },
    compareValues() {
      if (!this.id && (this.$v.form.$model.code || this.$v.form.$model.name)) return true;
      if (!this.id) return false;
      if (
        this.$v.form.$model.code != this.value.code ||
        this.$v.form.$model.name != this.value.name ||
        this.$v.form.$model.abbr != this.value.abbr ||
        this.$v.form.$model.active != this.value.active ||
        this.$v.form.$model.inclCommonDest != this.value.inclCommonDest ||
        this.$v.form.$model.isSchool != this.value.isSchool ||
        this.$v.form.$model.isVehicleLocation != this.value.isVehicleLocation ||
        this.$v.form.$model.zone != this.value.zone ||
        JSON.stringify(this.$v.form.address.$model) != JSON.stringify(this.value.address) ||
        this.$v.form.$model.siteAdmins != this.value.siteAdmins ||
        this.$v.form.$model.vehicleOrder != this.value.vehicleOrder
      )
        return true;
      return false;
    },
    isAddressFormInvalid() {
      return this.$refs.aform?.getCurrentForm()?.$invalid;
    },
    handleNewLocation({ address: locationAddress }) {
      if (!locationAddress) return;

      this.form.address = locationAddress;
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value = {}) {
        this.id = value.id;

        if (value.id && this.saved) {
          this.saved = false;
          this.cancel(true);
        } else {
          if (
            value.siteAdmins &&
            value.siteAdmins.length &&
            typeof value.siteAdmins[value.siteAdmins.length - 1] === 'string'
          ) {
            const sa = { text: value.siteAdmins[value.siteAdmins.length - 1] };
            value.siteAdmins.pop();
            value.siteAdmins.push(sa);
          }

          this.autocompleteSearchText = '';
          this.form = { ...newLocation, ...value, address: { ...(value.address ? value.address : {}) } };
        }
      },
    },
    valueRoles: {
      immediate: true,
      deep: true,
      handler: function (v) {
        this.locationRoles = {
          siteAdmins: v.siteAdmins.map((e) => e.userEmail),
          siteAdminsReadOnly: v.siteAdminsReadOnly.map((e) => e.userEmail),
          siteAdminsReportsOnly: v.siteAdminsReportsOnly.map((e) => e.userEmail),
          paysInvoices: v.paysInvoices.length ? v.paysInvoices[0].userEmail : '',
          vehicleOwner: v.vehicleOwner.length ? v.vehicleOwner[0].userEmail : '',
          specialNeedsVehicleOwner: v.specialNeedsVehicleOwner.length ? v.specialNeedsVehicleOwner[0].userEmail : '',
          nurses: v.nurses.length ? v.nurses[0].userEmail : '',
          nutritionists: v.nutritionists.length ? v.nutritionists[0].userEmail : '',
        };
      },
    },
  },
};
</script>

<style lang="scss">
.fixed-table table {
  table-layout: fixed;
}
.fixed-table td {
  overflow-x: hidden;
  text-overflow: ellipsis;
}
</style>
